import React, { createContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { axiosGet } from '../utils/axiosGet';

export const ReportDataContext = createContext();

export const ReportDataProvider = ({ children }) => {
  const [reportData, setReportData] = useState({
    nominations: 0,
    nominationsContinuing: 0,
    nominationsNew: 0,
    candidatesNominatedFemale: 0,
    nominationsFemaleNew: 0,
    nominationsContinuingFemale: 0,
    nominationsContinuingNotSubmitted: 0,
    nominationsContinuingWithdrawn: 0,
    nominationsMale: 0,
    nominationsFemale: 0,
    nominationsPreferNotToAnswer: 0,
  });

  useEffect(() => {
    const fetchReportData = async () => {
      const response = await axiosGet('nomination-reports');

      if (!response?.data) {
        return;
      }

      const { data } = response;

      setReportData({
        ...data,
        nominationsPreferNotToAnswer: data.nominationspreferNotToAnswer,
        nominationsMale: data.nominationsMan,
        nominationsFemale: data.nominationsWoman,
      });
    };

    fetchReportData();
  }, []);

  return (
    <ReportDataContext.Provider value={reportData}>
      {children}
    </ReportDataContext.Provider>
  );
};

ReportDataProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
