import React, { useContext, useEffect, useState } from 'react';

import PageTitle from '../layouts/PageTitle';
import Markdown from './Forms/Elements/Markdown';
import DownloadFile from './Forms/Elements/DownloadFile';
import { arrayToString } from '../../utils/arrayToString';
import { formatDateDDMMYYYY } from '../../utils/formatDateDDMMYYYY';
import { stringUcFirst } from '../../utils/stringUcFirst';
import { handleDownload } from '../../utils/handleDownload';
import { getNominationRoundYear } from '../../utils/getNominationRoundYear';

import { CommonCollections } from '../../App';
import { getNominationFormData } from '../../utils/getNominationFormData';
import { useStaticCollections } from '../../context/StaticCollections';
import { useAdminCollections } from '../../context/AdminCollections';
import { useLoggedInUserContextProvider } from '../../context/LoggedInUserContext';
import { strapiUrl } from '../../utils/constants';

const NominationViewProposer = () => {
  const { userDetails } = useLoggedInUserContextProvider();
  const {
    australianStatus,
    binariesTypes,
    electionTypes,
    formStepsArray,
    genderTypes,
    nonAasRefereesArray,
    sectionalCommittees,
    sectionalCommitteeHistoryArray,
    stateTerritories,
    supporterTypes,
    titlesTypes,
    organisations,
    users,
  } = useStaticCollections();

  const { candidateStateArray, nominationArray, nominationState } =
    useAdminCollections();
  const { nominationId, refereeReports } = useContext(CommonCollections);

  const [formViewValues, setFormViewValues] = useState({});
  const [formFields, setFormFields] = useState([]);
  const [currentNominationState, setCurrentNominationState] = useState([]);
  const [currentCandidateState, setCurrentCandidateState] = useState([]);

  const [
    nominationSectionalCommitteesHistory,
    setNominationSectionalCommitteesHistory,
  ] = useState({});
  const formattedBinaryTypes = Object.fromEntries(
    Object.entries(binariesTypes).map(([key, value]) => [
      parseInt(key) - 1,
      value,
    ]),
  );

  const committeeYears = [
    { key: 'sectionalCommitteeYearOne', label: 'Primary committee year 1' },
    { key: 'sectionalCommitteeYearTwo', label: 'Primary committee year 2' },
    { key: 'sectionalCommitteeYearThree', label: 'Primary committee year 3' },
    { key: 'sectionalCommitteeYearFour', label: 'Primary committee year 4' },
  ];

  const renderCommitteeYear = (year) => {
    const yearValue = nominationSectionalCommitteesHistory[year.key];
    return yearValue ? (
      <div key={year.key}>
        <span className="fw-bold">{year.label}</span>
        <p>{yearValue ?? ''}</p>
      </div>
    ) : null;
  };

  useEffect(() => {
    try {
      (async () => {
        if (nominationId) {
          const currentNomination = nominationArray.find(
            (item) => item.id === nominationId,
          );

          let nominationFormValues = currentNomination?.attributes?.value
            ? JSON.parse(currentNomination.attributes.value)
            : null;

          if (!nominationFormValues) {
            nominationFormValues = await getNominationFormData(
              currentNomination,
              organisations,
            );
          }
          setFormViewValues(nominationFormValues);

          const nominationSectionalCommitteeHistory =
            sectionalCommitteeHistoryArray.find(
              (item) => item.attributes.nominationId === nominationId,
            );

          setNominationSectionalCommitteesHistory(
            nominationSectionalCommitteeHistory?.attributes ?? '',
          );

          setCurrentNominationState(
            nominationState.find(
              (nominationStateItem) =>
                nominationStateItem?.attributes?.nominationId === nominationId,
            ),
          );

          if (currentNomination && currentNomination?.attributes?.candidateId) {
            setCurrentCandidateState(
              candidateStateArray.find(
                (candidateStateItem) =>
                  candidateStateItem.attributes.candidateId ===
                  currentNomination.attributes.candidateId,
              ),
            );
          }

          setFormFields(formStepsArray);
        }
      })();
    } catch (error) {
      console.error('Error in NominationViewProposer:', error);
    }
  }, [
    nominationId,
    organisations,
    nominationArray,
    formStepsArray,
    sectionalCommitteeHistoryArray,
    nominationState,
    candidateStateArray,
  ]);

  const sortedFormFields = [...formFields].sort(
    (a, b) => a.attributes.formStep - b.attributes.formStep,
  );

  const pdfUrls = [];

  const candidateName =
    `${formViewValues?.name} ${formViewValues?.lastName} ${getNominationRoundYear()}`
      .toLowerCase()
      .replace(/\s+/g, '-');

  const urlsWithRefereeIds = refereeReports.reduce((acc, report) => {
    if (
      report.attributes.nominationId === nominationId &&
      report.attributes.report !== null &&
      report.attributes.refereeArchived !== true &&
      (report.attributes.refereeStatusId === 2 ||
        report.attributes.refereeStatusId === 4)
    ) {
      const reportData = report.attributes.report.data;
      if (reportData) {
        acc.push({
          refereeUserId: users[report.attributes.refereeUserId],
          path: `${strapiUrl}${reportData.attributes.url}`,
          independentAssessor: report.attributes.independentAssessor,
        });
      }
    }
    return acc;
  }, []);

  const refereeReportUrls =
    userDetails.roles.includes('admin') ||
    userDetails.roles.includes('super-admin') ||
    userDetails.roles.includes('secretary') ||
    userDetails.roles.includes('committee-member') ||
    userDetails.roles.includes('committee-chair')
      ? urlsWithRefereeIds
      : null;
  return (
    <>
      <PageTitle activeMenu="View Nomination" motherMenu="Nominations" />

      <div className="card">
        <div className="card-header">
          <h4 className="card-title">Nomination View</h4>
          <button
            className="btn btn-success"
            type="button"
            onClick={() =>
              handleDownload(candidateName, pdfUrls, refereeReportUrls)
            }
            aria-label="Download"
          >
            <i className="bi bi-download" />
          </button>
        </div>
        {formViewValues &&
          formViewValues?.length !== 0 &&
          sortedFormFields.map((item, index) => {
            const filteredFormFields = Object.values(
              item.attributes.formField,
            ).filter(
              (formFieldItem) =>
                formFieldItem.name === 'sectionalCommittee' ||
                formFieldItem.name === 'phdAwardedYear' ||
                formFieldItem.name === 'nationalityId' ||
                formFieldItem.name === 'australianStatusId' ||
                formFieldItem.name === 'genderId' ||
                formFieldItem.name === 'organisationId' ||
                formFieldItem.name === 'stateTerritoryId' ||
                (formFieldItem.name &&
                  formFieldItem.name.includes('referee')) ||
                formFieldItem.__component !==
                  'field-collection.field-collection',
            );

            const fieldDescriptionClassName = { marginTop: '70px' };
            const formTextStyle = {
              width: '55%',
            };

            const firstDivHeadingStyle =
              index === 0
                ? { width: '55%', paddingTop: '60px', paddingBottom: '60px' }
                : { width: '55%', paddingTop: '40px', paddingBottom: '20px' };

            return (
              <React.Fragment key={index}>
                <div className="card-body text-left">
                  <div
                    className="mx-auto text-center"
                    style={firstDivHeadingStyle}
                  >
                    <h3>{`${item.attributes.formStep}. ${item.attributes.heading}`}</h3>
                  </div>
                  {filteredFormFields.map((filteredFormFieldItem, indexTwo) => {
                    let currentType = [];
                    let currentNonAasReferee = {};

                    const filePath =
                      formViewValues[`${filteredFormFieldItem.name}FilePath`];
                    if (filePath) {
                      pdfUrls.push({
                        name: filteredFormFieldItem.name,
                        path: filePath,
                      });
                    }

                    if (filteredFormFieldItem.name === 'electionTypeId') {
                      currentType = electionTypes;
                    }

                    if (filteredFormFieldItem.name === 'titleId') {
                      currentType = titlesTypes;
                    }

                    if (filteredFormFieldItem.name === 'genderId') {
                      currentType = genderTypes;
                    }

                    if (filteredFormFieldItem.name === 'stateTerritoryId') {
                      currentType = stateTerritories;
                    }

                    if (
                      filteredFormFieldItem.name === 'careerInterruption' ||
                      filteredFormFieldItem.name === 'meetsConduct'
                    ) {
                      currentType = formattedBinaryTypes;
                    }

                    if (filteredFormFieldItem.name === 'diverseBackground') {
                      currentType = { 0: 'No', 1: 'Yes' };
                    }

                    if (
                      filteredFormFieldItem.name === 'supporterOneTypeId' ||
                      filteredFormFieldItem.name === 'supporterTwoTypeId' ||
                      filteredFormFieldItem.name === 'supporterThreeTypeId' ||
                      filteredFormFieldItem.name === 'supporterFourTypeId'
                    ) {
                      currentType = supporterTypes;
                    }

                    if (filteredFormFieldItem.name === 'australianStatusId') {
                      currentType = australianStatus;
                    }

                    if (
                      formViewValues &&
                      filteredFormFieldItem?.name?.includes('referee') &&
                      formViewValues[
                        `nonAas${stringUcFirst(filteredFormFieldItem.name)}Id`
                      ]
                    ) {
                      const nonAasRefereeId =
                        formViewValues[
                          `nonAas${stringUcFirst(filteredFormFieldItem.name)}Id`
                        ].value;

                      currentNonAasReferee = nonAasRefereesArray.find(
                        (itemTest) => itemTest.id === nonAasRefereeId,
                      );
                    }

                    return (
                      <div
                        key={indexTwo}
                        className="col-md-12 mb-xxl-4 mx-auto"
                        style={formTextStyle}
                      >
                        {/* Round year */}
                        {filteredFormFieldItem.name === 'electionTypeId' &&
                          currentNominationState?.attributes?.roundYear && (
                            <>
                              <span className="fw-bold">Round Year</span>
                              <p>
                                {currentNominationState?.attributes?.roundYear}
                              </p>
                            </>
                          )}

                        {/* Last considered */}
                        {filteredFormFieldItem.name === 'electionTypeId' &&
                          currentNominationState?.attributes
                            ?.lastConsideredDate && (
                            <>
                              <span className="fw-bold">Last Considered</span>
                              <p>
                                {formatDateDDMMYYYY(
                                  currentNominationState?.attributes
                                    ?.lastConsideredDate || '',
                                )}
                              </p>
                            </>
                          )}

                        {/* Relationships */}
                        {formViewValues[filteredFormFieldItem.name] !==
                          undefined &&
                          typeof formViewValues[filteredFormFieldItem.name] ===
                            'number' &&
                          !filteredFormFieldItem.name.includes('referee') && (
                            <div className="col-md-12">
                              {filteredFormFields[indexTwo - 1] &&
                              filteredFormFields[indexTwo - 1].__component ===
                                'fieldset-description.fieldset-description' ? (
                                <div style={fieldDescriptionClassName}>
                                  <Markdown
                                    text={`#### ${
                                      filteredFormFields[indexTwo - 1]
                                        .fieldsetDescription
                                    }`}
                                    className="mb-3"
                                  />
                                </div>
                              ) : (
                                ''
                              )}
                              <span className="fw-bold">
                                {filteredFormFieldItem.label}
                              </span>
                              <p>
                                {` ${
                                  // eslint-disable-next-line no-nested-ternary
                                  currentType &&
                                  currentType[
                                    formViewValues[filteredFormFieldItem.name]
                                  ]
                                    ? currentType[
                                        formViewValues[
                                          filteredFormFieldItem.name
                                        ]
                                      ]
                                    : formViewValues[filteredFormFieldItem.name]
                                }`}
                              </p>
                            </div>
                          )}

                        {/* Other gender */}
                        {filteredFormFieldItem.name === 'genderId' &&
                          formViewValues.otherGender && (
                            <>
                              <span className="fw-bold">Other gender</span>
                              <p>{formViewValues.otherGender}</p>
                            </>
                          )}

                        {/* Sectional committee - label */}
                        {filteredFormFieldItem.name === 'sectionalCommittee' &&
                          parseInt(formViewValues.electionTypeId) !== 2 && (
                            <div>
                              <span className="fw-bold">
                                Primary Sectional Committee
                              </span>
                              <p>
                                Corresponding Member and Special Election
                                Committee
                              </p>
                            </div>
                          )}

                        {/* Sectional committee */}
                        {filteredFormFieldItem.name === 'sectionalCommittee' &&
                          formViewValues.sectionalCommitteeId &&
                          formViewValues.sectionalCommitteeId !== 'none' && (
                            <div>
                              <span className="fw-bold">
                                Primary Sectional Committee
                              </span>
                              <p>
                                {formViewValues.deprecatedSectionalCommitteeLabel &&
                                formViewValues.deprecatedSectionalCommitteeLabel !==
                                  ''
                                  ? formViewValues.deprecatedSectionalCommitteeLabel
                                  : sectionalCommittees[
                                      formViewValues.sectionalCommitteeId
                                    ]}
                              </p>
                            </div>
                          )}

                        {/* Sectional committee - discipline descriptor */}
                        {filteredFormFieldItem.name === 'sectionalCommittee' &&
                          formViewValues.disciplineDescriptor &&
                          formViewValues.disciplineDescriptor.length !== 0 && (
                            <div>
                              <span className="fw-bold">
                                Discipline Descriptors
                              </span>
                              <p>{` ${arrayToString(
                                formViewValues.disciplineDescriptor,
                              )}`}</p>
                            </div>
                          )}

                        {/* Sectional committee - interdisciplinary committee */}
                        {filteredFormFieldItem.name === 'sectionalCommittee' &&
                          formViewValues.interdisciplinaryCommittee !==
                            undefined &&
                          formViewValues.interdisciplinaryCommittee.length !==
                            0 && (
                            <div>
                              <span className="fw-bold ">
                                Interdisciplinary Candidates
                              </span>
                              <p>{` ${arrayToString(
                                formViewValues.interdisciplinaryCommittee,
                              )}`}</p>
                            </div>
                          )}

                        {/* Sectional committee - overlap sectional committee */}
                        {filteredFormFieldItem.name === 'sectionalCommittee' &&
                          formViewValues.overlapSectionalCommitteeId &&
                          formViewValues.overlapSectionalCommitteeId !==
                            'none' &&
                          formViewValues.overlapSectionalCommitteeId !== 0 && (
                            <div>
                              <span className="fw-bold">
                                Overlap Sectional Committee
                              </span>
                              <p>
                                {
                                  sectionalCommittees[
                                    formViewValues.overlapSectionalCommitteeId
                                  ]
                                }
                              </p>
                            </div>
                          )}

                        {/* Sectional committee  - overlap discipline descriptor */}
                        {filteredFormFieldItem.name === 'sectionalCommittee' &&
                          formViewValues.overlapDisciplineDescriptor !==
                            undefined &&
                          formViewValues.overlapDisciplineDescriptor.length !==
                            0 && (
                            <div>
                              <span className="fw-bold">
                                Overlap discipline descriptors
                              </span>
                              <p>{` ${arrayToString(
                                formViewValues.overlapDisciplineDescriptor,
                              )}`}</p>
                            </div>
                          )}

                        {/* Sectional committee history -  */}
                        {filteredFormFieldItem.name === 'sectionalCommittee' &&
                          sectionalCommitteeHistoryArray.length > 0 && (
                            <div className="mt-xl-5">
                              <h4 className="mb-3">
                                Sectional Committee history
                              </h4>
                              {committeeYears.map((year) =>
                                renderCommitteeYear(year),
                              )}
                            </div>
                          )}

                        {/* Field set descriptions */}
                        {typeof formViewValues[filteredFormFieldItem.name] ===
                          'string' &&
                          formViewValues[filteredFormFieldItem.name] !==
                            'none' &&
                          Number.isNaN(
                            parseInt(
                              formViewValues[filteredFormFieldItem.name],
                            ),
                          ) &&
                          formViewValues[filteredFormFieldItem.name] !== '' &&
                          !filteredFormFieldItem.name.includes('referee') && (
                            <div className="col-md-12">
                              {filteredFormFields[indexTwo - 1] &&
                              filteredFormFields[indexTwo - 1].__component ===
                                'fieldset-description.fieldset-description' ? (
                                <div style={fieldDescriptionClassName}>
                                  <Markdown
                                    text={`#### ${
                                      filteredFormFields[indexTwo - 1]
                                        .fieldsetDescription
                                    }`}
                                    className="mb-3"
                                  />
                                </div>
                              ) : (
                                ''
                              )}
                              <span className="fw-bold">
                                {filteredFormFieldItem.label}
                              </span>
                              <p>{` ${
                                formViewValues[filteredFormFieldItem.name]
                              }`}</p>
                            </div>
                          )}

                        {/* Existing string values in all steps */}
                        {typeof formViewValues[filteredFormFieldItem.name] ===
                          'string' &&
                          !Number.isNaN(
                            parseInt(
                              formViewValues[filteredFormFieldItem.name],
                            ),
                          ) &&
                          formViewValues[filteredFormFieldItem.name] !== '' &&
                          !filteredFormFieldItem.name.includes('referee') &&
                          filteredFormFieldItem.name !== 'dob' && (
                            <div
                              className={`col-md-12 ${
                                filteredFormFieldItem.name === 'meetsConduct'
                                  ? 'mt-7'
                                  : ''
                              }`}
                            >
                              {filteredFormFields[indexTwo - 1] &&
                              filteredFormFields[indexTwo - 1].__component ===
                                'fieldset-description.fieldset-description' ? (
                                <div style={fieldDescriptionClassName}>
                                  <Markdown
                                    text={`#### ${
                                      filteredFormFields[indexTwo - 1]
                                        .fieldsetDescription
                                    }`}
                                    className="mb-3"
                                  />
                                </div>
                              ) : (
                                ''
                              )}
                              <span className="fw-bold">
                                {filteredFormFieldItem.label}
                              </span>
                              <p>
                                {` ${
                                  // eslint-disable-next-line no-nested-ternary
                                  currentType &&
                                  currentType[
                                    formViewValues[filteredFormFieldItem.name]
                                  ]
                                    ? currentType[
                                        formViewValues[
                                          filteredFormFieldItem.name
                                        ]
                                      ]
                                    : formViewValues[filteredFormFieldItem.name]
                                }`}
                                {filteredFormFieldItem.name ===
                                  'australianStatusId' &&
                                formViewValues.arrivalDate
                                  ? `: ${formatDateDDMMYYYY(
                                      formViewValues.arrivalDate,
                                    )}`
                                  : ''}
                              </p>
                            </div>
                          )}

                        {/* Number of years considered */}
                        {filteredFormFieldItem.name === 'electionTypeId' &&
                          currentCandidateState?.attributes?.consideredYears &&
                          formViewValues?.consideredYears !== '0' && (
                            <>
                              <span className="fw-bold">
                                Number of years previously considered
                              </span>
                              <p>
                                {formViewValues?.consideredYears ||
                                  currentCandidateState?.attributes
                                    ?.consideredYears ||
                                  ''}
                              </p>
                            </>
                          )}

                        {/* 1. Candidate’s Details - NationalityId */}
                        {filteredFormFieldItem.name &&
                          filteredFormFieldItem.name === 'australianStatusId' &&
                          formViewValues.nationalityId && (
                            <div
                              className={`col-md-12 ${
                                filteredFormFieldItem.name === 'meetsConduct'
                                  ? 'mt-7'
                                  : ''
                              }`}
                            >
                              <span className="fw-bold">
                                {formViewValues.australianStatusId
                                  ? filteredFormFieldItem.label
                                  : 'Nationality'}
                              </span>
                              <p>
                                {formViewValues.nationalityId
                                  ? arrayToString(formViewValues.nationalityId)
                                  : ''}
                              </p>
                            </div>
                          )}

                        {/* Strings - dates DD/MM/YYYY */}
                        {typeof formViewValues[filteredFormFieldItem.name] ===
                          'string' &&
                          !Number.isNaN(
                            parseInt(
                              formViewValues[filteredFormFieldItem.name],
                            ),
                          ) &&
                          formViewValues[filteredFormFieldItem.name] !== '' &&
                          !filteredFormFieldItem.name.includes('referee') &&
                          filteredFormFieldItem.name === 'dob' && (
                            <div
                              className={`col-md-12 ${
                                filteredFormFieldItem.name === 'meetsConduct'
                                  ? 'mt-7'
                                  : ''
                              }`}
                            >
                              {filteredFormFields[indexTwo - 1] &&
                              filteredFormFields[indexTwo - 1].__component ===
                                'fieldset-description.fieldset-description' ? (
                                <div style={fieldDescriptionClassName}>
                                  <Markdown
                                    text={`#### ${
                                      filteredFormFields[indexTwo - 1]
                                        .fieldsetDescription
                                    }`}
                                    className="mb-3"
                                  />
                                </div>
                              ) : (
                                ''
                              )}
                              <span className="fw-bold">
                                {filteredFormFieldItem.label}
                              </span>
                              <p>
                                {` ${
                                  currentType &&
                                  currentType[
                                    formViewValues[filteredFormFieldItem.name]
                                  ]
                                    ? currentType[
                                        formViewValues[
                                          filteredFormFieldItem.name
                                        ]
                                      ]
                                    : formatDateDDMMYYYY(
                                        formViewValues[
                                          filteredFormFieldItem.name
                                        ],
                                      )
                                }`}
                              </p>
                            </div>
                          )}

                        {/* 1. Candidate’s Details - Boolean */}
                        {typeof formViewValues[filteredFormFieldItem.name] ===
                          'boolean' &&
                          formViewValues[filteredFormFieldItem.name] !== '' && (
                            <div className="col-md-12">
                              {filteredFormFields[indexTwo - 1] &&
                              filteredFormFields[indexTwo - 1].__component ===
                                'fieldset-description.fieldset-description' ? (
                                <div style={fieldDescriptionClassName}>
                                  <Markdown
                                    text={`#### ${
                                      filteredFormFields[indexTwo - 1]
                                        .fieldsetDescription
                                    }`}
                                    className="mb-3"
                                  />
                                </div>
                              ) : (
                                ''
                              )}
                              <span className="fw-bold">
                                {filteredFormFieldItem.label}
                              </span>
                              <p>
                                {` ${
                                  formViewValues[filteredFormFieldItem.name] ===
                                  true
                                    ? 'Yes'
                                    : 'No'
                                }`}
                              </p>
                            </div>
                          )}

                        {/* Files */}
                        {formViewValues[
                          `${filteredFormFieldItem.name}FileName`
                        ] &&
                          formViewValues[
                            `${filteredFormFieldItem.name}FileName`
                          ] !== '' && (
                            <div className="col-md-12">
                              {filteredFormFields[indexTwo - 1] &&
                              filteredFormFields[indexTwo - 1].__component ===
                                'fieldset-description.fieldset-description' ? (
                                <div style={fieldDescriptionClassName}>
                                  <Markdown
                                    text={`#### ${
                                      filteredFormFields[indexTwo - 1]
                                        .fieldsetDescription
                                    }`}
                                    className="mb-3"
                                  />
                                </div>
                              ) : (
                                ''
                              )}
                              <span className="fw-bold">
                                {filteredFormFieldItem.label}
                              </span>
                              <div className="mt-2">
                                <DownloadFile
                                  text={
                                    formViewValues[
                                      `${filteredFormFieldItem.name}FileName`
                                    ]
                                  }
                                  url={
                                    formViewValues[
                                      `${filteredFormFieldItem.name}FilePath`
                                    ]
                                  }
                                  name={`${formViewValues.name}${formViewValues.lastName}_${filteredFormFieldItem.name}`}
                                />
                              </div>
                            </div>
                          )}

                        {filteredFormFieldItem.name &&
                        formViewValues[filteredFormFieldItem.name] &&
                        typeof formViewValues[filteredFormFieldItem.name] ===
                          'object' &&
                        formViewValues[filteredFormFieldItem.name].label ? (
                          <div
                            className={`col-md-12 ${/(proposer|seconder)/i.test(filteredFormFieldItem.label) ? 'mt-xl-5' : ''}`}
                          >
                            {filteredFormFields[indexTwo - 1] &&
                            filteredFormFields[indexTwo - 1].__component ===
                              'fieldset-description.fieldset-description' ? (
                              <div style={fieldDescriptionClassName}>
                                <Markdown
                                  text={`#### ${
                                    filteredFormFields[indexTwo - 1]
                                      .fieldsetDescription
                                  }`}
                                  className="mb-3"
                                />
                              </div>
                            ) : (
                              ''
                            )}
                            <span className="fw-bold">
                              {filteredFormFieldItem.label}
                            </span>
                            <p>{`${
                              formViewValues[filteredFormFieldItem.name].label
                            }`}</p>
                          </div>
                        ) : (
                          ''
                        )}

                        {/* Other organisation */}
                        {filteredFormFieldItem.name === 'organisationId' &&
                          formViewValues.otherOrganisation && (
                            <>
                              <span className="fw-bold">
                                Other Organisation
                              </span>
                              <p>{formViewValues.otherOrganisation}</p>
                            </>
                          )}

                        {/* 10. Referees - referee name */}
                        {formViewValues[
                          `${filteredFormFieldItem.name}FellowId`
                        ] &&
                        Object.values(
                          formViewValues[
                            `${filteredFormFieldItem.name}FellowId`
                          ],
                        ).length > 0 &&
                        formattedBinaryTypes[
                          formViewValues[`${filteredFormFieldItem.name}`]
                        ] ? (
                          <>
                            <h4
                              className={
                                filteredFormFieldItem.name === 'refereeOne'
                                  ? ''
                                  : 'mt-xl-5'
                              }
                            >
                              Referee{' '}
                              {filteredFormFieldItem.label.match(/\d+/)[0]}
                            </h4>
                            <div className="col-md-12">
                              {filteredFormFields[indexTwo - 1] &&
                              filteredFormFields[indexTwo - 1].__component ===
                                'fieldset-description.fieldset-description' ? (
                                <div style={fieldDescriptionClassName}>
                                  <Markdown
                                    text={`#### ${
                                      filteredFormFields[indexTwo - 1]
                                        .fieldsetDescription
                                    }`}
                                    className="mb-3"
                                  />
                                </div>
                              ) : (
                                ''
                              )}
                              <span className="fw-bold">
                                {filteredFormFieldItem.label}
                              </span>
                              <p>
                                {`${
                                  formattedBinaryTypes[
                                    formViewValues[
                                      `${filteredFormFieldItem.name}`
                                    ]
                                  ]
                                }`}
                                <br />
                              </p>
                              <span className="fw-bold">
                                Select existing AAS fellow
                              </span>
                              <p>
                                {` ${
                                  formViewValues[
                                    `${filteredFormFieldItem.name}FellowId`
                                  ].label
                                }`}
                              </p>
                            </div>
                          </>
                        ) : (
                          typeof filteredFormFieldItem.name === 'string' &&
                          formViewValues[
                            `nonAas${stringUcFirst(filteredFormFieldItem.name)}Id`
                          ] && (
                            <>
                              <h4
                                className={
                                  filteredFormFieldItem.name === 'refereeOne'
                                    ? ''
                                    : 'mt-xl-5'
                                }
                              >
                                Referee{' '}
                                {filteredFormFieldItem.label.match(/\d+/)[0]}
                              </h4>
                              <div className="col-md-12">
                                {filteredFormFields[indexTwo - 1] !==
                                  undefined &&
                                filteredFormFields[indexTwo - 1].__component ===
                                  'fieldset-description.fieldset-description' ? (
                                  <div style={fieldDescriptionClassName}>
                                    <Markdown
                                      text={`#### ${
                                        filteredFormFields[indexTwo - 1]
                                          .fieldsetDescription
                                      }`}
                                      className="mb-3"
                                    />
                                  </div>
                                ) : (
                                  ''
                                )}
                                <span className="fw-bold">
                                  Use an AAS Fellow as referee?
                                </span>
                                <p>
                                  No
                                  <br />
                                </p>
                                <span className="fw-bold">
                                  Select non AAS referee
                                </span>

                                <p>
                                  {formViewValues[
                                    `nonAas${stringUcFirst(filteredFormFieldItem.name)}Id`
                                  ]?.label ?? ''}
                                </p>
                                {currentNonAasReferee?.attributes
                                  ?.organisation && (
                                  <>
                                    <span className="fw-bold">
                                      Organisation
                                    </span>
                                    <p>
                                      {
                                        currentNonAasReferee?.attributes
                                          ?.organisation
                                      }
                                    </p>
                                  </>
                                )}
                                {currentNonAasReferee?.attributes
                                  ?.memberships && (
                                  <>
                                    <span className="fw-bold">Memberships</span>
                                    <p>
                                      {
                                        currentNonAasReferee?.attributes
                                          ?.memberships
                                      }
                                    </p>
                                  </>
                                )}
                              </div>
                            </>
                          )
                        )}
                      </div>
                    );
                  })}
                </div>
              </React.Fragment>
            );
          })}
      </div>
    </>
  );
};

export default NominationViewProposer;
