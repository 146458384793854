import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import ValidationIndependentAssessor from '../Validation/ValidationIndependentAssessor';
import WizardWrapper from './WizardWrapper';
import { axiosGet } from '../../../../utils/axiosGet';
import { axiosPost } from '../../../../utils/axiosPost';
import { CommonCollections } from '../../../../App';
import { useStaticCollections } from '../../../../context/StaticCollections';
import { useAdminCollections } from '../../../../context/AdminCollections';
import { getIndependentAssessorFormData } from '../../../../utils/getIndependentAssessorFormData';
import { useLoggedInUserContextProvider } from '../../../../context/LoggedInUserContext';
import { formatIndependentAssessorData } from '../../../../utils/formatIndependentAssessorData';
import ModalConfirmation from '../../ModalConfirmation';

const WizardIndependentAssessor = () => {
  const { userDetails } = useLoggedInUserContextProvider();
  const { sectionalCommittees, activeFellowsArray, nonAasRefereesArray } =
    useStaticCollections();
  const { candidates, nominationArray } = useAdminCollections();
  const {
    nominationId,
    setNominationId,
    refreshDynamicCollections,
    setRefreshDynamicCollections,
    organisations,
  } = useContext(CommonCollections);

  const { refreshRefereeReports, setRefreshRefereeReports } =
    useContext(CommonCollections);

  const [formState, setFormState] = useState({});
  const [formStatus, setFormStatus] = useState(1);
  const [refereeData, setRefereeData] = useState({});
  const [originalRefereeData, setOriginalRefereeData] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState({});
  const [modalBodyText, setModalBodyText] = useState('');

  const handleCancelSelect = () => {
    setShowModal(false);
  };

  const onSubmit = (data) => {
    setShowModal(true);

    const independentAssessorData = formatIndependentAssessorData(data);
    const formattedData = {
      ...independentAssessorData,
      ...refereeData,
      nominationId,
    };
    setFormData(formattedData);

    const getRefereeLabel = (submittedData, refereeNumber) => {
      const isFellow = formattedData[`referee${refereeNumber}`] === 1;
      const fellowId = formattedData[`referee${refereeNumber}FellowId`];
      const nonAasId = formattedData[`nonAasReferee${refereeNumber}Id`];
      const newNonAas = formattedData[`newNonAasReferee${refereeNumber}`];
      if (isFellow) {
        if (
          originalRefereeData[`referee${refereeNumber}FellowId`]?.value !==
          fellowId
        ) {
          const fellowData = activeFellowsArray.find(
            (fellow) => fellow.id === fellowId,
          );
          return fellowData?.attributes?.label || '';
        }
        return '';
      }

      if (nonAasId) {
        if (
          originalRefereeData[`nonAasReferee${refereeNumber}Id`]?.value !==
          nonAasId
        ) {
          const nonAasData = nonAasRefereesArray.find(
            (ref) => ref.id === nonAasId,
          );
          return nonAasData?.attributes?.label || '';
        }
        return '';
      }

      return newNonAas?.label || '';
    };

    const refereeLabels = ['Seven', 'Eight', 'Nine'].map((refereeNumber) =>
      getRefereeLabel(
        formattedData,
        refereeNumber,
        activeFellowsArray,
        nonAasRefereesArray,
      ),
    );

    const [refereeSevenLabel, refereeEightLabel, refereeNineLabel] =
      refereeLabels;

    setModalBodyText(
      `Please note the following independent assessors will be notified for references:
    
${refereeSevenLabel ? `**${refereeSevenLabel}** ` : ''} </br>
${refereeEightLabel ? `**${refereeEightLabel}** ` : ''}</br>
${refereeNineLabel ? `**${refereeNineLabel}** ` : ''}</br>
Please click the green icon <i class="bi bi-arrow-counterclockwise action-button bg-success"></i> to remove referee from the list`,
    );
  };

  const handleConfirmSelect = async () => {
    try {
      const response = await axiosPost('independent-assessor-post', {
        data: formData,
      });
      if (response.status === 200) {
        setNominationId(null);
        setRefreshRefereeReports(!refreshRefereeReports);
        setRefreshDynamicCollections(!refreshDynamicCollections);
      }
    } catch (error) {
      console.error('Error submitting independent assessor form:', error);
    }
  };
  const {
    register,
    setValue,
    watch,
    control,
    resetField,
    setError,
    reset,
    formState: { errors },
    handleSubmit,
    trigger,
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(ValidationIndependentAssessor),
  });

  useEffect(() => {
    const fetchData = async () => {
      if (!nominationId) return;

      try {
        const { data: existingFellows } = await axiosGet(
          'independent-assessor-get-users',
          { nominationId },
        );
        setValue('existingFellows', JSON.stringify(existingFellows));

        const { data: nominationReferee } = await axiosGet(
          'get-independent-assessors',
          { data: nominationId },
        );

        if (
          nominationReferee.refereeSevenTypeId !== null ||
          nominationReferee.refereeEightTypeId !== null ||
          nominationReferee.refereeNineTypeId !== null
        ) {
          const refData = await getIndependentAssessorFormData(
            nominationReferee,
            organisations,
          );
          setOriginalRefereeData(refData);
          setFormStatus(1);
          Object.keys(refData).forEach((item) => {
            setValue(item, refData[item]);
          });
          trigger();
        }

        const nomination = nominationArray.find(
          (nom) => nom.id === nominationId,
        );
        const sectionalCommittee =
          sectionalCommittees[nomination.attributes.sectionalCommitteeId];
        const candidate = candidates[nomination.attributes.candidateId];
        setValue('candidate', candidate);
        setRefereeData({
          candidate,
          sectionalCommittee,
          electionTypeId: nomination.attributes.electionTypeId,
          testEmail: userDetails.email,
        });
      } catch (error) {
        console.error('Error setting independent assessor form values:', error);
      }
    };
    trigger();
    fetchData();
  }, [
    userDetails,
    nominationId,
    reset,
    setValue,
    trigger,
    candidates,
    nominationArray,
    sectionalCommittees,
    organisations,
  ]);

  return (
    <>
      <WizardWrapper
        activeMenu="Independent Assessor"
        motherMenu="Home"
        formName="independentAssessors"
        formState={formState}
        setFormState={setFormState}
        setValue={setValue}
        register={register}
        errors={errors}
        control={control}
        resetField={resetField}
        setError={setError}
        watch={watch}
        handleSubmit={handleSubmit}
        onSubmit={onSubmit}
        formStatusId={formStatus}
        trigger={trigger}
      />
      <ModalConfirmation
        showModal={showModal}
        handleCancelSelect={handleCancelSelect}
        bodyText={modalBodyText}
        handleConfirmSelect={handleConfirmSelect}
      />
    </>
  );
};

export default WizardIndependentAssessor;
