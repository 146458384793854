import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Alert } from 'react-bootstrap';

const Notification = ({
  message = '',
  title = '',
  setFilterInstance = null,
}) => {
  const [showNotification, setShowNotification] = useState(true);
  const [isFilterPending, setIsFilterPending] = useState(true);

  const handleButtonClick = () => {
    if (setFilterInstance) {
      setFilterInstance({
        columnName: 'requestEmailDate',
        filterValue: isFilterPending ? 'pending' : '',
      });
      setIsFilterPending((prev) => !prev);
    }
  };

  const formatMessage = (text) => {
    const matches = text.match(/[^()]+\([^()]+\)/g);
    if (!matches) return text;

    const limitedMatches = matches.slice(0, 3);
    const isTruncated = matches.length > 3;

    return (
      <>
        {limitedMatches.map((part, index) => (
          <span key={index}>
            {part}
            <br />
          </span>
        ))}
        {isTruncated && <span>...</span>}
      </>
    );
  };

  const containsKeyword = ['required', 'error'].some((word) =>
    [message, title].some((text) => text.toLowerCase().includes(word)),
  );

  return (
    showNotification && (
      <div className="notification-container">
        <Alert
          variant="danger"
          className="notification d-flex align-items-center"
        >
          <button
            type="button"
            className="btn-close"
            aria-label="Close alert"
            onClick={() => setShowNotification(false)}
          >
            &times;
          </button>

          <div
            className={`icon-container me-3 ${
              containsKeyword ? 'text-danger' : 'text-success'
            }`}
          >
            <span
              className={`bi ${
                containsKeyword
                  ? 'bi-exclamation-triangle-fill'
                  : 'bi-check-square-fill'
              } fs-2`}
            />
          </div>

          <div className="media">
            <div className="media-body">
              {title && (
                <div className="d-flex align-items-center">
                  <h5 className="mt-1 mb-2 text-white me-2">{title}</h5>
                </div>
              )}

              <p className="mt-1 mb-0">{formatMessage(message)}</p>
              {title && setFilterInstance && (
                <div className="text-end mt-2">
                  <button
                    type="button"
                    className="btn btn-light btn-sm px-3 mt-2"
                    onClick={handleButtonClick}
                  >
                    {isFilterPending ? 'Apply Filter 🙂' : 'Clear Filter 🙂'}
                  </button>
                </div>
              )}
            </div>
          </div>
        </Alert>
      </div>
    )
  );
};

Notification.propTypes = {
  message: PropTypes.string,
  title: PropTypes.string,
  setFilterInstance: PropTypes.func,
};

export default Notification;
