import React from 'react';
import ReactApexChart from 'react-apexcharts';
import PropTypes from 'prop-types';

const ApexBar3 = ({ values, xLabels }) => {
  console.log(values);
  const data = {
    series: [
      {
        name: 'Female candidates',
        data: values,
      },
    ],
    options: {
      chart: {
        type: 'bar',
        height: 350,
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '55%',
          endingShape: 'rounded',
        },
      },
      dataLabels: {
        enabled: false,
      },

      legend: {
        show: true,
        fontSize: '12px',
        fontWeight: 300,

        labels: {
          colors: 'black',
        },
        position: 'bottom',
        horizontalAlign: 'center',
        markers: {
          width: 19,
          height: 19,
          strokeWidth: 0,
          radius: 19,
          strokeColor: '#fff',
          fillColors: ['#00a2ff'],
          offsetX: 0,
          offsetY: 0,
        },
      },
      yaxis: {
        labels: {
          style: {
            colors: '#3e4954',
            fontSize: '14px',
            fontFamily: 'Poppins',
            fontWeight: 100,
          },
        },
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent'],
      },
      xaxis: {
        categories: xLabels,
      },
      fill: {
        colors: ['#00a2ff'],
        opacity: 1,
      },
      tooltip: {
        y: {
          formatter(val) {
            return `${val} nominations`;
          },
        },
      },
    },
  };

  const { options } = data;
  const { series } = data;
  return (
    <div id="chart" className="line-chart-style bar-chart">
      <ReactApexChart
        options={options}
        series={series}
        type="bar"
        height={300}
      />
    </div>
  );
};

ApexBar3.propTypes = {
  xLabels: PropTypes.array,
  values: PropTypes.array,
};

export default ApexBar3;
