import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';

import NominationCard from './NominationCard';

import { CommonCollections } from '../../../App';
import { useStaticCollections } from '../../../context/StaticCollections';
import { useAdminCollections } from '../../../context/AdminCollections';
import { useLoggedInUserContextProvider } from '../../../context/LoggedInUserContext';

const TableNominationShortlist = ({
  nominationsInSectionalCommittee,
  overlapNominations,
  handleSelectClick,
}) => {
  const [open, setOpen] = useState({});
  const {
    activeFellows,
    declineReasons,
    nonAasReferees,
    refereeStatuses,
    sectionalCommittees,
  } = useStaticCollections();
  const { candidates } = useAdminCollections();
  const { setNominationId, refereeReports } = useContext(CommonCollections);
  const { userDetails } = useLoggedInUserContextProvider();

  const toggleCollapse = (nominationId) => {
    setOpen((prevOpen) => ({
      ...prevOpen,
      [nominationId]: !prevOpen[nominationId],
    }));
  };

  return (
    <>
      {[...nominationsInSectionalCommittee, ...overlapNominations].map(
        (nomination, index) => (
          <NominationCard
            key={index}
            nomination={nomination}
            candidates={candidates}
            refereeReports={refereeReports}
            sectionalCommittees={sectionalCommittees}
            activeFellows={activeFellows}
            nonAasReferees={nonAasReferees}
            declineReasons={declineReasons}
            refereeStatuses={refereeStatuses}
            handleSelectClick={handleSelectClick}
            toggleCollapse={toggleCollapse}
            open={open}
            setNominationId={setNominationId}
            userDetails={userDetails}
          />
        ),
      )}
    </>
  );
};

TableNominationShortlist.propTypes = {
  nominationsInSectionalCommittee: PropTypes.array.isRequired,
  overlapNominations: PropTypes.array.isRequired,
  handleSelectClick: PropTypes.func.isRequired,
};

export default TableNominationShortlist;
