export const nominationColumns = [
  'id',
  'createdAt',
  'updatedAt',
  'nominationRoundYear',
  'nominationStatus',
  'electionType',
  'consideredYears',
  'lastConsideredDate',
  'title',
  'name',
  'lastName',
  'phone',
  'address',
  'email',
  'stateTerritory',
  'gender',
  'otherGender',
  'dob',
  'countryOfBirth',
  'australianStatus',
  'postNominal',
  'jobTitle',
  'organisation',
  'otherOrganisation',
  'phdAwardedYear',
  'noPhd',
  'personalInformation',
  'diverseBackground',
  'nominationConsentFilePath',
  'retractedPapers',
  'correctedPapers',
  'publicationConcerns',
  'disclosureDeclarationFilePath',
  'nationalityId',
  'emailEducationalInstitution',
  'arrivalDate',
  'careerInterruption',
  'scientificExcellence',
  'citation',
  'sectionalCommittee',
  'deprecatedSectionalCommitteeLabel',
  'disciplineDescriptor',
  'overlapCommittee',
  'overlapSectionalCommitteeId',
  'overlapDisciplineDescriptor',
  'interdisciplinaryCommittee',
  'proposerFellow',
  'proposerSignatureFilePath',
  'seconderFellow',
  'seconderSignatureFilePath',
  'supporterOneTypeId',
  'supporterOneFellow',
  'supporterOneSignatureFilePath',
  'supporterTwoTypeId',
  'supporterTwoFellow',
  'supporterTwoSignatureFileName',
  'supporterThreeTypeId',
  'supporterThreeFellow',
  'supporterThreeSignatureFilePath',
  'supporterFourTypeId',
  'supporterFourFellow',
  'supporterFourSignatureFilePath',
  'meetsConduct',
  'extendedCitationFilePath',
  'curriculumVitaeFilePath',
  'significantPublicationsFilePath',
  'publicationsFilePath',
  'refereeOne',
  'refereeTwo',
  'refereeThree',
  'refereeFour',
  'refereeFive',
  'refereeSix',
  'refereeSeven',
  'refereeEight',
  'refereeNine',
  'refereeOneFellow',
  'refereeTwoFellow',
  'refereeThreeFellow',
  'refereeFourFellow',
  'refereeFiveFellow',
  'refereeSixFellow',
  'refereeSevenFellow',
  'refereeEightFellow',
  'refereeNineFellow',
  'nonAasRefereeOne',
  'nonAasRefereeTwo',
  'nonAasRefereeThree',
  'nonAasRefereeFour',
  'nonAasRefereeFive',
  'nonAasRefereeSix',
  'nonAasRefereeSeven',
  'nonAasRefereeEight',
  'nonAasRefereeNine',
  'nonPreferredReferee',
];
