export const arrayToCSV = (data, filterColumns = []) => {
  if (!data.length) return '';
  const headers = filterColumns.length ? filterColumns : Object.keys(data[0]);
  const csvRows = [];

  csvRows.push(headers.join(','));

  for (const row of data) {
    if (row) {
      const values = headers.map((header) => {
        let field = row[header] != null ? row[header] : '';
        field = String(field).replace(/"/g, '""');
        if (/[,"\n]/.test(field)) {
          field = `"${field}"`;
        }

        return field;
      });

      csvRows.push(values.join(','));
    }
  }

  return csvRows.join('\n');
};
