export const calculateColumnWidths = (
  refs,
  baseWidth = 100,
  maxWidth = 300,
) => {
  const newWidths = {};
  refs.forEach((cell, index) => {
    if (cell) {
      const textLength = cell.textContent.length;
      if (textLength > 10) {
        newWidths[index] = Math.min(maxWidth, textLength * baseWidth);
      }
    }
  });
  return newWidths;
};
