import { jsPDF } from 'jspdf';
import { PDFDocument, rgb } from 'pdf-lib';

const camelCaseToTitle = (camelCaseStr) =>
  camelCaseStr
    .replace(/([a-z])([A-Z])/g, '$1 $2')
    .replace(/^\w/, (c) => c.toUpperCase());

const getTextWidth = (text, fontSize) => {
  const averageCharWidth = fontSize * 0.5;
  return text.length * averageCharWidth;
};

const numberWords = ['One', 'Two', 'Three', 'Four', 'Five', 'Six']; // Array of number words

export const handleDownload = async (candidate, pdfUrls, refereeReportUrls) => {
  const elements = document.querySelectorAll('.card');
  if (refereeReportUrls) {
    let independentAssessorCount = 0;
    refereeReportUrls.forEach((referee, index) => {
      if (referee.independentAssessor) {
        referee.name = `independentAssessor${numberWords[independentAssessorCount]}`;
        independentAssessorCount += 1;
      } else {
        referee.name = `referee${numberWords[index]}`;
      }
    });
  }

  if (elements.length === 0) {
    console.error('No elements with class "card" found.');
    return;
  }

  // format the element before converting to pdf
  const clonedElements = Array.from(elements).map((element) => {
    const clonedElement = element.cloneNode(true);

    clonedElement.classList.remove('card');
    // Remove card title
    const h4Element = clonedElement.querySelector('h4.card-title');
    if (h4Element && h4Element.textContent.trim() === 'Nomination View') {
      h4Element.remove();
    }

    const h3Elements = clonedElement.querySelectorAll('h3');
    h3Elements.forEach((h3) => {
      h3.style.fontSize = '24px';
    });

    // Remove inline styles from all child elements
    const allChildElements = clonedElement.querySelectorAll('*');
    allChildElements.forEach((child) => child.removeAttribute('style'));

    // Remove all inline styles
    clonedElement.removeAttribute('style');

    // Remove all classes to remove class-based styling
    clonedElement.className = '';
    allChildElements.forEach((child) => (child.className = ''));

    // Remove buttons
    const buttons = clonedElement.querySelectorAll('button');
    buttons.forEach((button) => button.remove());

    // Make all span text bold to differentiate labels from field value
    const spans = clonedElement.querySelectorAll('span');
    spans.forEach((span) => {
      span.style.fontWeight = 'bold';
    });

    return clonedElement;
  });

  const mergedPdfsArray = [...pdfUrls, ...(refereeReportUrls || [])];
  const pdf = jsPDF('p', 'mm', 'a4');

  const htmlContent = Array.from(clonedElements)
    .map((clonedElement) => clonedElement.outerHTML)
    .join('<br><br>');
  const tempElement = document.createElement('div');
  tempElement.innerHTML = htmlContent;
  tempElement.style.fontFamily = 'Arial, sans-serif';
  tempElement.style.fontSize = '20px';
  tempElement.style.lineHeight = '2';
  tempElement.style.color = 'black';
  tempElement.style.pageBreakInside = 'avoid';
  tempElement.style.width = '920px';
  tempElement.style.height = '50%';
  tempElement.style.whiteSpace = 'normal';
  tempElement.style.overflow = 'hidden';
  tempElement.style.marginRight = '20px';
  document.body.appendChild(tempElement);
  try {
    await pdf.html(tempElement, {
      callback: async () => {
        document.body.removeChild(tempElement);

        const newPdfDoc = await PDFDocument.create();

        // Front page
        const frontPage = newPdfDoc.addPage([595.28, 841.89]);
        const title = 'Nomination Details';
        const titleWidth = getTextWidth(title, 16);

        frontPage.drawText(title, {
          x: (frontPage.getWidth() - titleWidth) / 2,
          y: 400,
          size: 18,
          color: rgb(0, 0, 0),
        });

        const jsPdfBytes = pdf.output('arraybuffer');
        const additionalPdfPromises = mergedPdfsArray.map((pdfUrl) =>
          fetch(pdfUrl.path).then((response) => {
            if (!response.ok) {
              throw new Error(
                `Failed to fetch additional PDF: ${response.statusText}`,
              );
            }
            return response.arrayBuffer();
          }),
        );

        const additionalPdfArrayBuffers = await Promise.all(
          additionalPdfPromises,
        );
        const [generatedPdfDoc, ...additionalPdfDocs] = await Promise.all([
          PDFDocument.load(jsPdfBytes, { ignoreEncryption: true }),
          ...additionalPdfArrayBuffers.map((buffer) =>
            PDFDocument.load(buffer, { ignoreEncryption: true }),
          ),
        ]);

        const generatedPages = await newPdfDoc.copyPages(
          generatedPdfDoc,
          generatedPdfDoc.getPageIndices(),
        );

        generatedPages.forEach((page) => {
          const { width, height } = page.getSize();
          page.setMediaBox(0, 0, width, height + 10);
          page.setCropBox(0, 0, width, height + 10);
        });
        generatedPages.forEach((page) => newPdfDoc.addPage(page));

        let blankPageNumber = 1;

        for (const additionalPdfDoc of additionalPdfDocs) {
          const blankPage = newPdfDoc.addPage([595.28, 841.89]);
          const blankPageIndex = newPdfDoc.getPageCount() - 1;
          const blankPageContext = newPdfDoc.getPage(blankPageIndex);
          const publicationName = camelCaseToTitle(
            mergedPdfsArray[blankPageNumber - 1].name,
          );
          const textWidth = getTextWidth(publicationName, 16);

          blankPageContext.drawText(publicationName, {
            x: (blankPage.getWidth() - textWidth) / 2,
            y: 400,
            size: 18,
            color: rgb(0, 0, 0),
          });

          blankPageNumber += 1;

          const additionalPages = await newPdfDoc.copyPages(
            additionalPdfDoc,
            additionalPdfDoc.getPageIndices(),
          );
          additionalPages.forEach((page) => newPdfDoc.addPage(page));
        }

        const newPdfBytes = await newPdfDoc.save();
        const newPdfBlob = new Blob([newPdfBytes], { type: 'application/pdf' });
        const newPdfUrl = URL.createObjectURL(newPdfBlob);

        const link = document.createElement('a');
        link.href = newPdfUrl;
        link.download = `${candidate}.pdf`;
        document.body.appendChild(link);
        link.click();
      },
      x: 20,
      y: 5,
      width: 180,
      height: tempElement.scrollHeight + 50,
      html2canvas: {
        scale: 0.2,
        dpi: 300,
        letterRendering: true,
        scrollX: 0,
        scrollY: 0,
        windowHeight: 10,
        logging: false,
        useCORS: true,
        allowTaint: true,
      },
      autoPaging: 'text',
      pagebreak: { mode: ['avoid-all', 'css', 'legacy'] },
    });
  } catch (error) {
    console.error('Error generating PDF:', error);
    alert(
      'Download unsuccessful. Please reach out to our support team at ict@science.org.au for assistance in resolving the issue.',
    );
  }
};
