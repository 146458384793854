import React, { useState } from 'react';
import { Dropdown, DropdownButton } from 'react-bootstrap';

import PageTitle from '../layouts/PageTitle';
import ModalConfirmation from './ModalConfirmation';
import SectionalCommittees from './SectionalCommittees';
import TableNominationShortlist from './NominationShortlist/TableNominationShortlist';

import { useStaticCollections } from '../../context/StaticCollections';
import { useAdminCollections } from '../../context/AdminCollections';
import { useLoggedInUserContextProvider } from '../../context/LoggedInUserContext';

import { handleConfirmSelect } from '../../utils/handleConfirmSelect';
import { addCmseCommitteeToSectionalCommitteeArray } from '../../utils/addCmseCommitteeToSectionalCommitteeArray';

const NominationShortlist = () => {
  const { refreshNominations, setRefreshNominations } = useAdminCollections();
  const { sectionalCommitteesArray } = useStaticCollections();
  const { userDetails } = useLoggedInUserContextProvider();

  const isAdmin =
    userDetails?.roles?.includes('super-admin') ||
    userDetails?.roles?.includes('admin');

  const sectionalCommitteesWithCmse = addCmseCommitteeToSectionalCommitteeArray(
    sectionalCommitteesArray,
    true,
  );

  const [showModal, setShowModal] = useState(false);
  const [selectedCandidate, setSelectedCandidate] = useState(null);
  const [selectedNominationId, setSelectedNominationId] = useState(null);
  const [selectedCommittee, setSelectedCommittee] = useState(null);
  const [selectedNominationStatus, setSelectedStatus] = useState(null);

  const handleSelectClick = (nominationId, candidate) => {
    setSelectedCandidate(candidate);
    setSelectedNominationId(nominationId);
    setShowModal(true);
  };

  const handleCancelSelect = () => setShowModal(false);

  const handleCommitteeChange = (committeeId) =>
    setSelectedCommittee(committeeId);

  const getShortlistStatusTitle = () => {
    if (selectedNominationStatus === null) return 'Filter by Status';
    if (JSON.stringify(selectedNominationStatus) === JSON.stringify([3]))
      return 'Shortlisted';
    if (JSON.stringify(selectedNominationStatus) === JSON.stringify([2]))
      return 'Not Shortlisted';
    return 'Filter by Status';
  };

  return (
    <>
      <PageTitle motherMenu="Nominations" activeMenu="Shortlist candidate" />
      <div className="table-controls mt-xl-5 mb-4">
        {isAdmin && (
          <DropdownButton
            id="dropdown-basic-button"
            variant="outline-primary"
            title={
              selectedCommittee
                ? sectionalCommitteesWithCmse.find(
                    (item) => item.id === parseInt(selectedCommittee),
                  )?.attributes.name || 'Filter by Sectional Committee'
                : 'Filter by Sectional Committee'
            }
          >
            <Dropdown.Item onClick={() => handleCommitteeChange(null)}>
              All
            </Dropdown.Item>
            {sectionalCommitteesWithCmse.map((item) => (
              <Dropdown.Item
                key={item.id}
                onClick={() => handleCommitteeChange(item.id)}
              >
                {item.attributes.name}
              </Dropdown.Item>
            ))}
          </DropdownButton>
        )}

        <DropdownButton
          id="shortlist-status-dropdown"
          variant="outline-primary"
          title={getShortlistStatusTitle()}
        >
          <Dropdown.Item onClick={() => setSelectedStatus(null)}>
            All
          </Dropdown.Item>
          <Dropdown.Item onClick={() => setSelectedStatus([3])}>
            Shortlisted
          </Dropdown.Item>
          <Dropdown.Item onClick={() => setSelectedStatus([2])}>
            Not Shortlisted
          </Dropdown.Item>
        </DropdownButton>
      </div>
      <SectionalCommittees
        TableComponent={TableNominationShortlist}
        statusTypes={[2, 3]}
        handleSelectClick={handleSelectClick}
        selectedCommittee={selectedCommittee}
        selectedNominationStatus={selectedNominationStatus}
      />
      <ModalConfirmation
        showModal={showModal}
        handleCancelSelect={handleCancelSelect}
        bodyText={`Are you sure you want to **shortlist** ${selectedCandidate}?`}
        handleConfirmSelect={handleConfirmSelect}
        rowId={`${selectedNominationId}`}
        apiIdentifier="nomination-shortlist"
        refreshCollections={refreshNominations}
        setRefreshCollections={setRefreshNominations}
        setShowModal={setShowModal}
      />
    </>
  );
};

export default NominationShortlist;
