import React, { useState, useMemo, useCallback } from 'react';
import Select from 'react-select';
import { useAdminCollections } from '../../context/AdminCollections';
import { useStaticCollections } from '../../context/StaticCollections';
import { getAllNominationData } from '../../utils/getAllNominationData';
import { arrayToCSV } from '../../utils/arrayToCsv';
import { axiosGet } from '../../utils/axiosGet';
import { formatDraftNominationData } from '../../utils/formatDraftNominationData';
import { nominationColumns } from '../../utils/availableColumnsNominationDownload';

const NominationCSVExport = () => {
  const { nominationArray } = useAdminCollections();
  const {
    electionTypes,
    nominationStatusTypes,
    sectionalCommitteesWithCmse,
    organisations,
    titlesTypes,
    stateTerritories,
    genderTypes,
    australianStatus,
  } = useStaticCollections();

  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [selectedColumns, setSelectedColumns] = useState([]);
  const [downloadFullNomination, setDownloadFullNomination] = useState(true);
  const [includeNonValidatedDraft, setIncludeNonValidatedDraft] =
    useState(true);

  const nominationStatuses = Object.entries(nominationStatusTypes).map(
    ([value, label]) => ({
      value,
      label,
    }),
  );
  const [selectedStatus, setSelectedStatus] = useState(nominationStatuses);

  const columnOptions = useMemo(
    () => nominationColumns.map((col) => ({ value: col, label: col })),
    [],
  );

  const handleColumnChange = useCallback((selectedOptions) => {
    setSelectedColumns(selectedOptions.map((option) => option.value));
  }, []);

  const handleStatusChange = useCallback((selectedOptions) => {
    setSelectedStatus(selectedOptions);
  }, []);

  const handleDownloadFullNominationToggle = useCallback(() => {
    setDownloadFullNomination((prev) => {
      const newValue = !prev;
      if (newValue) {
        setSelectedColumns(nominationColumns);
        setSelectedStatus(nominationStatuses);
        setIncludeNonValidatedDraft(true);
      } else {
        setSelectedColumns([]);
        setSelectedStatus([]);
      }
      return newValue;
    });
  }, [nominationStatuses]);

  const generateCSVDownload = (data) => {
    const csv = `\uFEFF${arrayToCSV(data, selectedColumns)}`;
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = 'nomination_data.csv';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const downloadNomination = async () => {
    try {
      setLoading(true);
      setProgress(0);
      const filteredNominations = nominationArray.filter((nomination) =>
        selectedStatus.some(
          (status) =>
            status.value ===
            String(nomination.attributes.nominationStatusTypeId),
        ),
      );

      const nominationDataArray = await Promise.all(
        filteredNominations.map((nomination) =>
          getAllNominationData(
            nomination,
            nominationStatusTypes,
            electionTypes,
            organisations,
            titlesTypes,
            stateTerritories,
            genderTypes,
            australianStatus,
            sectionalCommitteesWithCmse,
          ).then((data) => {
            setProgress((prev) => prev + 100 / nominationArray.length);
            return data;
          }),
        ),
      );

      if (includeNonValidatedDraft) {
        const draftNominationResponses = await axiosGet('nomination-drafts');
        const draftNominationArray = await Promise.all(
          draftNominationResponses.data.data.map((draft) =>
            formatDraftNominationData(
              JSON.parse(draft.attributes.value),
              nominationStatusTypes,
              electionTypes,
              titlesTypes,
              stateTerritories,
              genderTypes,
              australianStatus,
              sectionalCommitteesWithCmse,
            ).then((data) => ({
              id: draft.id,
              createdAt: draft.attributes.createdAt,
              updatedAt: draft.attributes.updatedAt,
              ...data,
            })),
          ),
        );
        generateCSVDownload([...nominationDataArray, ...draftNominationArray]);
      } else {
        generateCSVDownload([...nominationDataArray]);
      }
    } catch (error) {
      console.error('Error downloading nominations:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="row mb-3">
      <div className="col-md-6 mb-3">
        <div className="card h-100 d-flex flex-column justify-content-center admin-card">
          <div className="card-header">
            <h4 className="card-title">Download nomination</h4>
          </div>
          <div className="card-body flex-grow-1">
            <div className="form-check form-switch mt-3">
              <label className="form-check-label" htmlFor="toggleDownload">
                Download full nomination
              </label>
              <input
                className="form-check-input"
                type="checkbox"
                id="toggleDownload"
                checked={downloadFullNomination}
                onChange={handleDownloadFullNominationToggle}
              />
            </div>
            {!downloadFullNomination && (
              <div className="mt-3">
                <div className="form-check form-switch mt-3">
                  <label className="form-check-label" htmlFor="toggleDownload">
                    Include non validated draft
                  </label>
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="toggleDownload"
                    checked={includeNonValidatedDraft}
                    onChange={() =>
                      setIncludeNonValidatedDraft((prev) => !prev)
                    }
                  />
                </div>

                <label className="form-label" htmlFor="columnFilter">
                  Select nominations to include
                </label>
                <Select
                  closeMenuOnSelect={false}
                  isMulti
                  options={nominationStatuses}
                  value={nominationStatuses.filter((option) =>
                    selectedStatus.some(
                      (status) => status.value === option.value,
                    ),
                  )}
                  onChange={handleStatusChange}
                  className="mb-3"
                />

                <label className="form-label" htmlFor="columnFilter">
                  Select columns to include in CSV
                </label>
                <Select
                  closeMenuOnSelect={false}
                  isMulti
                  options={columnOptions}
                  value={columnOptions.filter((option) =>
                    selectedColumns.includes(option.value),
                  )}
                  onChange={handleColumnChange}
                  className="mb-3"
                />
              </div>
            )}
          </div>
          <div className="mt-auto p-3 d-flex justify-content-end align-items-center flex-grow-1">
            {loading ? (
              <div className="height50 progress w-100">
                <div
                  className="progress-bar"
                  role="progressbar"
                  style={{ width: `${progress}%` }}
                  aria-valuenow={progress}
                  aria-valuemin="0"
                  aria-valuemax="100"
                >
                  {Math.round(progress)}%
                </div>
              </div>
            ) : (
              <button
                type="button"
                className="btn btn-dark"
                onClick={downloadNomination}
                aria-label="Download CSV"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="Press to download CSV"
              >
                <i className="bi bi-download" /> Download
              </button>
            )}
          </div>
        </div>
      </div>

      <div className="col-md-6" />
    </div>
  );
};

export default NominationCSVExport;
