import { useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const useNavigationPrompt = (when, message) => {
  const navigate = useNavigate();
  const location = useLocation();
  const prevLocation = useRef(location);
  const isPrompting = useRef(false);

  useEffect(() => {
    if (!when) return;

    const handleBeforeUnload = (event) => {
      event.preventDefault();
      event.returnValue =
        message || 'You have unsaved changes. Are you sure you want to leave?';
    };

    const handlePopState = () => {
      if (isPrompting.current) return;
      isPrompting.current = true;

      const confirmExit = window.confirm(
        message || 'You have unsaved changes. Are you sure you want to leave?',
      );
      if (!confirmExit) {
        window.history.pushState(null, '', window.location.pathname);
      } else {
        prevLocation.current = location;
      }

      isPrompting.current = false;
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    window.addEventListener('popstate', handlePopState);

    window.history.pushState(null, '', window.location.pathname);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      window.removeEventListener('popstate', handlePopState);
    };
  }, [when, message, navigate, location]);

  useEffect(() => {
    if (!when) {
      prevLocation.current = location;
    }
  }, [when, location]);
};

export default useNavigationPrompt;
