import React, { useState, useEffect, useRef } from 'react';
import { Card, Table } from 'react-bootstrap';
import PropTypes from 'prop-types';

import PageTitle from '../layouts/PageTitle';
import ModalConfirmation from './ModalConfirmation';
import SectionalCommittees from './SectionalCommittees';

import { useStaticCollections } from '../../context/StaticCollections';
import { useAdminCollections } from '../../context/AdminCollections';

import { calculateColumnWidths } from '../../utils/calculateColumnWidths';
import { calculateDynamicStyle } from '../../utils/calculateDynamicStyle';
import { handleConfirmSelect } from '../../utils/handleConfirmSelect';
import { arrayToString } from '../../utils/arrayToString';
import { renderUpdatedSpan } from '../../utils/renderUpdatedSpan';

const TableNominationElect = ({
  nominationsInSectionalCommittee,
  handleSelectClick,
}) => {
  const { electionTypes, nominationStatusTypes } = useStaticCollections();
  const { candidates } = useAdminCollections();
  const cellRefs = useRef([]);
  const [columnWidths, setColumnWidths] = useState({});

  useEffect(() => {
    setColumnWidths(calculateColumnWidths(cellRefs.current));
  }, [nominationsInSectionalCommittee.length]);

  return (
    <Card className="card-two mb-xl-5 table-responsive">
      <Card.Body>
        <Table bordered hover striped className="dataTable">
          <thead className="thead-dark">
            <tr>
              <th className="d-none">
                <b>Nomination Id</b>
              </th>
              <th>
                <b>Name</b>
              </th>
              <th>
                <b>Type</b>
              </th>
              <th>
                <b>Descriptor</b>
              </th>
              <th>
                <b>Status</b>
              </th>
              <th aria-label="Actions">
                <b />
              </th>
            </tr>
          </thead>
          <tbody>
            {nominationsInSectionalCommittee.map((nomination, index) => {
              const { nominationStatusTypeId } = nomination.attributes;
              const cells = [
                {
                  value: candidates[nomination.attributes.candidateId],
                },
                {
                  value: electionTypes[nomination.attributes.electionTypeId],
                },
                {
                  value: nomination.attributes.disciplineDescriptor
                    ? arrayToString(nomination.attributes.disciplineDescriptor)
                    : '',
                },
                {
                  value: nominationStatusTypes[nominationStatusTypeId],
                },
              ];

              return (
                <tr key={index}>
                  <td className="d-none">{nomination.id}</td>
                  {cells.map((cell, cellIndex) => (
                    <td
                      key={cellIndex}
                      ref={(el) => (cellRefs.current[cellIndex] = el)}
                      style={calculateDynamicStyle(columnWidths[cellIndex])}
                    >
                      {cell.value}{' '}
                      {cellIndex === 0 &&
                        renderUpdatedSpan(nomination?.attributes?.updatedAt)}
                    </td>
                  ))}
                  <td className="text-center">
                    {nominationStatusTypeId !== 3 ? (
                      <i className="bi bi-check-square view-button" />
                    ) : (
                      <button
                        className="button-reset"
                        type="button"
                        aria-label="Select Candidate"
                        onClick={() =>
                          handleSelectClick(
                            `${nomination.id}`,
                            candidates[nomination.attributes.candidateId],
                          )
                        }
                      >
                        <span title="Elect Candidate">
                          <i className="bi bi-square action-button" />
                        </span>
                      </button>
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  );
};

TableNominationElect.propTypes = {
  nominationsInSectionalCommittee: PropTypes.array.isRequired,
  handleSelectClick: PropTypes.func.isRequired,
};

const NominationElect = () => {
  const { refreshNominations, setRefreshNominations } = useAdminCollections();
  const [showModal, setShowModal] = useState(false);
  const [selectedCandidate, setSelectedCandidate] = useState(null);
  const [selectedNominationId, setSelectedNominationId] = useState(null);

  const handleSelectClick = (nominationId, candidate) => {
    setSelectedCandidate(candidate);
    setSelectedNominationId(nominationId);
    setShowModal(true);
  };

  const handleCancelSelect = () => {
    setShowModal(false);
  };

  return (
    <>
      <PageTitle motherMenu="Nominations" activeMenu="Elect candidate" />
      <SectionalCommittees
        TableComponent={TableNominationElect}
        statusTypes={[3, 4]}
        handleSelectClick={handleSelectClick}
      />
      <ModalConfirmation
        showModal={showModal}
        handleCancelSelect={handleCancelSelect}
        bodyText={`Are you sure you want to **elect** ${selectedCandidate}?`}
        handleConfirmSelect={handleConfirmSelect}
        rowId={selectedNominationId}
        apiIdentifier="nomination-elect"
        refreshCollections={refreshNominations}
        setRefreshCollections={setRefreshNominations}
        setShowModal={setShowModal}
      />
    </>
  );
};

export default NominationElect;
